@import url('https://rsms.me/inter/inter-ui.css');

.container {
  max-width: 540px;
}
html, body {
  height: 100%;
  width: 100%;
  font-family: 'Inter UI', sans-serif;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}
.form-card {
  box-shadow: 0px 10px 80px 6px rgba(0,0,0,0.15);
}
.form, .results {
  padding: 12px;
}
.form {
  background: white;
}
.results {
  background: #81C784;
}
.labels p {
  opacity: 0.8;
}
.numbers p {
  font-weight: 700;
}
.example-btn-container {
  height: 2rem;
}
.example-btn {
  float: right;
}
.input-field {
  margin-bottom: 1.5rem;
}